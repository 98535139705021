import UnifiedLightboxModal from '@components/unified-lightbox/Lightbox';
import { openUnifiedLightboxAtom } from '@components/unified-lightbox/atoms';
import { getFilterByMediaType } from '@components/unified-lightbox/helpers';
import { useStorefrontEmptyStates } from '@feature-flags/hooks/Storefront/useStorefrontEmptyStates';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import noop from '@utils/noop';
import { Column, Row } from '@xo-union/tk-component-grid';
import { useSetAtom } from 'jotai';
import React, { type FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import AnalyticsConstants from '../../../../../../constants/analytics';
import { afterNextPaint } from '../../../../../../helpers/after-next-paint';
import MediaNotAvailable from '../../../../../components/media_not_available';
import Media from '../../../components/Media';
import Carousel from '../../../components/carousel';
import SocialSharing from '../../../components/social_sharing';
import { MediaCTA } from '../../Gallery/components/MediaSummary/components/MediaCTA/MediaCTA';
import { ButtonRole } from '../../Gallery/components/MediaSummary/components/MediaCTA/constants';
import useTrackMediaAnalytics from '../../Gallery/containers/Media/hooks/use-track-media-analytics';
import RfqModal from '../../InlineRFQ/components/RfqModal/RfqModal';
import { useInlineRfqForm } from '../../InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import FavoritesButton from '../../vendor_info/components/favorites_button';
import Styles from './MobileGallery.scss';
import LandscapeGalleryView from './components/landscape_gallery_view/LandscapeGalleryView';
import { MobileGalleryQuickLinks } from './components/mobile_gallery_quick_links';
import { MobilePhotoCount } from './components/mobile_photo_count';
import useTrackCarouselInteraction from './hooks/use-track-carousel-interaction';

interface StateProps {
	membership: Redux.Membership;
	vendor: Vendor.Decorated;
}

type NewMobileGalleryProps = StateProps;

const NewMobileGallery: FC<NewMobileGalleryProps> = (props) => {
	const [activeImageIndex, setActiveImageIndex] = useState(0);
	const isMobile = useAppSelector(selectIsMobile);
	const viewport = useAppSelector((state) => state.viewport);

	const { membership, vendor } = props;
	const carouselMedia = vendor.mediaSummary.media || [];
	const trackCarouselInteraction = useTrackCarouselInteraction(vendor);
	const trackMediaAnalytics = useTrackMediaAnalytics(vendor);

	const [isUnifiedLightboxModalOpen, setIsUnifiedLightboxModalOpen] =
		useState<boolean>(false);

	const setOpenUnifiedLightbox = useSetAtom(openUnifiedLightboxAtom);
	const isEmptyStateEnabled = useStorefrontEmptyStates();

	const onNavigationChange = useCallback(
		(index: number) => {
			setActiveImageIndex((prevState) => {
				trackCarouselInteraction(
					prevState < index ? 'swipe right' : 'swipe left',
					{
						mediaIndex: index,
						mediaType: carouselMedia?.[index].mediaType,
						item: carouselMedia?.[index].url,
					},
				);
				return index;
			});
		},
		[trackCarouselInteraction],
	);

	const formContext = useInlineRfqForm({
		initiator: 'photo gallery',
	});

	const handleOpenModal = async () => {
		afterNextPaint(() => {
			trackMediaAnalytics(activeImageIndex, carouselMedia?.[activeImageIndex]);
			setOpenUnifiedLightbox({
				indexInAllMedia: activeImageIndex,
				filter: getFilterByMediaType(
					carouselMedia?.[activeImageIndex].mediaType,
				),
				isEmptyStateEnabled,
			});
			setIsUnifiedLightboxModalOpen(true);
		});
	};

	const decorated360Tours = useMemo(
		() =>
			carouselMedia
				.map((mediaItem, index) => ({ index, ...mediaItem }))
				.filter((mediaItem) => mediaItem.mediaType === '360TOUR'),
		[carouselMedia],
	);

	const shouldShowQuickLinks = carouselMedia?.length > 1;

	return (
		<>
			<div className={Styles.landscapeView}>
				<LandscapeGalleryView />
			</div>
			<div className={Styles.portraitView} data-mobilegallery="portrait">
				<Row hGutters={false}>
					<Column xs="12">
						{decorated360Tours.length > 0 && (
							<div className={Styles.tourButtonContainer}>
								<MediaCTA
									mediaItems={decorated360Tours}
									singleButton={true}
									onClick={noop}
									role={ButtonRole.Tour}
									event={AnalyticsConstants.PLAY_TOUR}
								/>
							</div>
						)}
						<div className={Styles.buttonsContainer}>
							<SocialSharing
								vendor={vendor}
								icon="share_ios"
								viewport={viewport}
							/>
							<FavoritesButton
								membership={membership}
								sourceContent="storefront carousel"
								transparent={false}
								vendor={vendor}
							/>
						</div>
						<div
							className={`${Styles.mobileCarousel} ${
								isMobile ? Styles.mobileAppCarousel : ''
							}`}
							onClick={handleOpenModal}
							onKeyDown={handleOpenModal}
							role="button"
							tabIndex={0}
						>
							{vendor.mediaSummary.total > 1 && (
								<MobilePhotoCount
									currentIndex={activeImageIndex}
									totalNumber={vendor.mediaSummary.total}
								/>
							)}
							{carouselMedia && carouselMedia.length > 0 ? (
								<Carousel
									activeImageIndex={activeImageIndex}
									mediaLength={carouselMedia?.length || 0}
									renderNextButton={() => null}
									renderPreviousButton={() => null}
									onNavigationChange={onNavigationChange}
								>
									{carouselMedia.map((m, index) => (
										<Media
											lazy={index !== 0}
											mediaItem={m}
											width={450}
											height={250}
											key={m.id}
											imageClass={Styles.carouselMediaItem}
											preload={index === 0}
											smartCrop
										/>
									))}
								</Carousel>
							) : (
								<MediaNotAvailable />
							)}
						</div>
					</Column>
				</Row>
				{shouldShowQuickLinks && (
					<MobileGalleryQuickLinks
						media={carouselMedia}
						context={formContext}
					/>
				)}
				{isUnifiedLightboxModalOpen && (
					<UnifiedLightboxModal
						entryPoint="StorefrontGallery"
						isModalOpen={isUnifiedLightboxModalOpen}
						closeCallback={() => setIsUnifiedLightboxModalOpen(false)}
					/>
				)}
			</div>
			<RfqModal context={formContext} />
		</>
	);
};

export function mapStateToProps(state: Redux.State): StateProps {
	return {
		membership: state.membership,
		vendor: state.vendor.vendor as Vendor.Decorated,
	};
}

export default connect<StateProps, {}, {}>(mapStateToProps)(NewMobileGallery);
